import PlayItemData from "../dtos/PlayItemData";

interface PlaylistObject {
    id: number;
    link: string;
    name: string;
    art: string;
}

export default class ProxyIn {
    getPlaylist(mixedData: PlaylistObject[]): PlayItemData[] {
        const playlist:PlayItemData[] = [];
        if(mixedData.length) {
            mixedData.forEach((data: PlaylistObject) => {
                const playItem = new PlayItemData(data.id, data.name, data.link, data.art);
                playlist.push(playItem);
            })
        }

        return playlist;
    }
}