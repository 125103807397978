import VueSocketIO from "vue-socket.io";
import Vue from 'vue';
import Router from "vue-router";
import App from './App.vue';
import store from "./store/save-vuex-store";
import { io } from "socket.io-client";

const websocketURL = `wss://music.beintalk.com/`;
const socketConnection = io(websocketURL, {
    withCredentials: true,
});

const socket = new VueSocketIO({
    debug: process.env.DEBUG,
    connection: socketConnection,
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
    },
});

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
    ]
});

Vue.use(socket, store);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
