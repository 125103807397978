import { render, staticRenderFns } from "./PlaylistContainer.vue?vue&type=template&id=4ae0f389&scoped=true&"
import script from "./PlaylistContainer.vue?vue&type=script&lang=js&"
export * from "./PlaylistContainer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../music.beintalk.com/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ae0f389",
  null
  
)

export default component.exports