import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import localForage from 'localforage';

const vuexLocal = new VuexPersistence({
    storage: localForage,
    asyncStorage: true,
})

Vue.use(Vuex);

const mutations = {
    VOLUME_change(state, volume) {
        state.player.volume = volume;
    },
    SOCKET_connect(state) {
        state.isConnected = true;
    },
    SOCKET_disconnect(state) {
        state.isConnected = false;
    },
    SOCKET_GLOBAL_DATA(state, message) {
        state.connections = message.connections;
    }
};

export default new Vuex.Store({
    state: {
        loaded: true,
        isConnected: false,
        connections: 0,
        player: {
            volume: .8,
        }
    },
    mutations: mutations,
    plugins: [vuexLocal.plugin],
})


