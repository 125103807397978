<template>
  <div id="app">
    <div
        id="player-wrapper"
        :data-show-list="show_list ? 'true' : 'false'"
        :data-show-info="show_info ? 'true' : 'false'"
    >
      <div id="ripple-effect-list" :class="{ active: show_list == true }"></div>

      <div class="toolbar">
        <div
            id="ripple-effect-info"
            :class="{ active: show_info == true }"
        ></div>
        <div
            id="close"
            :class="{ show: show_list == true || show_info == true }"
            @click="
            show_list = false;
            show_info = false;
          "
        >
          <i class="fas fa-times"></i>
        </div>
        <div
            class="list-icon"
            @click="show_list = !show_list"
            v-if="show_list == false && show_info == false"
        >
          <i class="fas fa-stream"></i>
        </div>
        <div
            class="info-icon"
            @click="show_info = !show_info"
            v-if="show_list == false && show_info == false"
        >
          <i class="fas fa-info-circle"></i>
        </div>
      </div>
      <div class="info-container">
        <div class="info-wrapper">
          <div v-html="info"></div>
          <div class="flex flex-middle">
            <img src="/house-mixes.png"
                 alt="house-mixes.com"
                 style="filter: grayscale(.9)"
                 height="30"
                 align="baseline"
            > Big thanks to the HOUSE-MIXES.COM Project.
          </div>
        </div>
      </div>
      <playlist-container :player-data="playerData" v-on:changeTrack="changeTrack($event)"></playlist-container>
      <div class="player-container">
        <div class="bg-container">
          <div class="art" :style="{ backgroundImage: 'url(' +
                  currentArt +
                  ')'}"></div>
        </div>
        <div class="player-stuff">
          <div class="title-container">
            <div class="title">{{ book.title }}</div>
            <div class="author">{{ book.author }}</div>
          </div>
          <div class="cover-wrapper">
            <div class="cover-wrapper-inside">
              <div class="prev-next">
                <div
                    class="prev"
                    :class="{ disabled: audio_index <= 0 }"
                    @click="playPrev"
                >
                  <i class="fas fa-backward"></i>
                </div>

                <div
                    class="next"
                    :class="{ disabled: audio_index >= book.chapters.length - 1 }"
                    @click="playNext"
                >
                  <i class="fas fa-forward"></i>
                </div>

              </div>
              <spectrum
                  :pins="normlizeAudioPins"
                  v-on:average="updateAverage($event)"
              ></spectrum>
              <div class="cover" :style="coverArtTransition">
                <div
                    :style="{
                backgroundImage:
                  'url(' +
                  currentArt +
                  ')',
              }"
                ></div>
              </div>
            </div>
          </div>
          <div class="chapter">
            {{ book.chapters[audio_index] && book.chapters[audio_index].name }}
          </div>
          <div class="audio-bar-wrapper">
            <div class="audio-bar-container" @mousedown="barClick">
              <div class="bar" ref="bar">
                <span class="loading" v-if="is_loading == true"></span>
                <span class="played" :style="{ width: handlePosition }"></span>
                <span
                    class="buffered"
                    v-for="b in buffered"
                    v-bind:key="b.left"
                    :style="{ left: b.left, width: b.width }"
                ></span>
              </div>
              <div
                  class="handle"
                  id="handle"
                  :style="{ left: handlePosition }"
              ></div>
            </div>
            <div class="time-container">
              <div class="current">{{ currentTime }}</div>
              <div class="end">{{ endTime }}</div>
            </div>
          </div>
          <div class="controls">
            <div>
            </div>
            <div class="play-pause" @click="playAudio()">
              <i class="fas fa-play" v-if="is_playing === false"></i>
              <i class="fas fa-pause" v-if="is_playing === true"></i>
            </div>
            <div class="flex flex-end">
              <volume-control @volumeChanged="changeVolume" :initialVolume="getVolume()"
                              :key="$store.state.loaded"></volume-control>
            </div>
          </div>
          <div class="options">
            <div class="speed" @click="toggleSpeed">{{ speed }}x</div>
            <div
                class="repeat"
                @click="repeat = !repeat"
                :class="{ active: repeat == true }"
            >
              <i class="material-icons">repeat</i>
            </div>
            <div
                class="auto-playlist"
                @click="list_play = !list_play"
                :class="{ active: list_play == true }"
            >
              <i class="material-icons">playlist_play</i>
            </div>
          </div>
          <div class="connection-info" v-if="$store && $store.state && $store.state.isConnected">
            <small>Active Listeners: <span>{{ $store.state.connections }}</span></small>
          </div>
        </div>
      </div>

      <div id="error-box" :class="{ show: is_error == true }">
        {{ this.error_msg }}
      </div>
    </div>
  </div>
</template>

<script>
import Spectrum from "./components/Spectrum.vue";
import "./assets/scss/styles.scss";
import axios from "axios";
import ProxyIn from "@/Services/ProxyIn.ts";
import PlayerData from "@/dtos/PlayerDto.ts";
import PlaylistContainer from "@/components/PlaylistContainer";
import VolumeControl from "@/components/player/VolumeControl";

const book = {
  title: "Music is here",
  author: "PJ.SUGGESTED.MUSIC",
  cover_art:
      "https://www.zastavki.com/pictures/640x480/2020Girls___Beautyful_Girls_Beautiful_brown-eyed_girl_with_a_bouquet_of_white_wildflowers_149403_29.jpg",
  info: ["Music is outcome of our mind"],
  chapters: [],
};

export default {
  name: "Pjayer",
  components: {
    Spectrum,
    PlaylistContainer,
    VolumeControl,
  },
  data() {
    return {
      playerData: new PlayerData(this.$router),
      handle_clicked: false,
      handle_position: 0,
      bar: null,
      is_playing: false,
      is_loading: true,
      is_error: false,
      error_timeout: null,
      error_msg: "",
      audio_obj: null,
      audio_index: 0,
      duration: 0,
      current_time: 0,
      audio_pins: [],
      analyser: null,
      data_array: [],
      buffered: [],
      speed: 1,
      repeat: false,
      repeat_count: 0,
      list_play: true,
      show_list: false,
      book: book,
      show_info: false,
      cover_art_average: 0,
    };
  },
  mounted() {
    this.bar = this.$refs.bar.getBoundingClientRect();
    // let handle = this.$refs.handle;

    document.addEventListener("mousedown", this.mouseDown);
    document.addEventListener("mouseup", (e) => (this.handle_click = false));
    document.addEventListener("mousemove", this.moveHandler);

    window.addEventListener("resize", this.resizeHandler);

    // initiate audio obj
    this.loadBookData();
  },
  methods: {
    loadBookData() {
      // @todo add pagination
      axios
          .get("https://music.beintalk.com/api/v1/mp3/index")
          .then((response) => this.bookBuild(response.data));
    },
    bookBuild(responseData) {
      responseData && responseData.length &&
      responseData.forEach((bookData) => {
        this.book.chapters.push(bookData);
      });
      this.playerData.setPlaylist((new ProxyIn()).getPlaylist(this.book.chapters));
      this.changeTrack(this.playerData.initiateRoutes());
      this.initiateAudio();
    },
    initiateAudio() {
      this.audio_obj = new Audio(this.book.chapters[this.audio_index].link);
      this.audio_obj.crossOrigin = "anonymous";
      this.audio_obj.src = this.book.chapters[this.audio_index].link;
      this.audio_obj.load();

      this.audio_obj.addEventListener("loadeddata", () => {
        this.duration = this.audio_obj.duration;
      });

      this.audio_obj.addEventListener("ended", this.onEnded);
      this.audio_obj.addEventListener("progress", this.onProgress);
      this.audio_obj.addEventListener("loadedmetadata", this.onProgress);
      this.audio_obj.addEventListener(
          "playing",
          (e) => (this.is_playing = true)
      );
      this.audio_obj.addEventListener(
          "pause",
          (e) => (this.is_playing = false)
      );
      this.audio_obj.addEventListener(
          "canplay",
          (e) => (this.is_loading = false)
      );
      this.audio_obj.addEventListener(
          "seeking",
          (e) => (this.is_loading = true)
      );
      this.audio_obj.onerror = this.onError;
      this.audio_obj.volume = this.$store.state.player.volume;
      this.initMediaSession();
    },
    initMediaSession() {
      if ('mediaSession' in navigator) {
        navigator.mediaSession.setActionHandler('play', this.playAudio);
        navigator.mediaSession.setActionHandler('pause', this.pause);
        navigator.mediaSession.setActionHandler('previoustrack', this.playPrev);
        navigator.mediaSession.setActionHandler('nexttrack', this.playNext);
      }
    },
    onError() {
      let msg = this.audio_obj.error.code + `: ` + this.audio_obj.error.message;
      console.error("ERROR", msg);
      this.is_error = true;
      this.error_msg = msg;

      this.is_playing = false;
      this.is_loading = false;

      clearTimeout(this.error_timeout);
      this.error_timeout = setTimeout(() => {
        this.is_error = false;
        this.error_msg = "";
      }, 3500);
    },
    onEnded(event) {
      this.audio_obj.currentTime = 0;
      if (this.repeat && this.repeat_count < 1) {
        this.audio_obj.play();
        this.repeat_count = this.repeat_count + 1;
        return;
      }

      if (this.repeat_count == 1) {
        this.repeat_count = 0;
      }

      if (this.list_play) {
        const newIndex = this.audio_index + 1;
        if (newIndex < this.book.chapters.length) this.audio_index = newIndex;
        return;
      }

      this.is_playing = false;
      this.repeat = false;
    },
    onProgress(e) {
      const oneSecond = 100 / this.duration;
      const ranges = [];
      for (var i = 0; i < this.audio_obj.buffered.length; i++) {
        const start = this.audio_obj.buffered.start(i);
        const end = this.audio_obj.buffered.end(i);

        const position = {};
        position.left = oneSecond * start + "%";
        position.width = oneSecond * (end - start) + "%";

        ranges.push(position);
      }

      this.buffered = ranges;
    },
    updateAverage(event) {
      this.cover_art_average = event;
    },
    timeUpdated() {
      this.current_time = this.audio_obj.currentTime;
      this.analyser.getByteTimeDomainData(this.data_array);
      this.audio_pins = Array.from(this.data_array);

      requestAnimationFrame(this.timeUpdated);
    },
    playNext() {
      const newIndex = this.audio_index + 1;
      if (newIndex >= this.book.chapters.length) return;
      this.audio_index = newIndex;
    },
    playPrev() {
      const newIndex = this.audio_index - 1;

      if (newIndex < 0) return;
      this.audio_index = newIndex;
    },
    initAnalyser() {
      const ctx = new AudioContext();
      this.analyser = ctx.createAnalyser();
      const audioSrc = ctx.createMediaElementSource(this.audio_obj);

      // we could configure the analyser: e.g. analyser.fftSize (for further infos read the spec)
      // 256
      this.analyser.minDecibels = -90;
      this.analyser.maxDecibels = -10;
      this.analyser.smoothingTimeConstant = 0.75;
      this.analyser.fftSize = 128;

      // we have to connect the MediaElementSource with the analyser
      audioSrc.connect(this.analyser);
      this.analyser.connect(ctx.destination);

      const bufferLength = this.analyser.frequencyBinCount;
      this.data_array = new Uint8Array(bufferLength);
    },
    playAudio() {
      if (this.is_playing) this.audio_obj.pause();
      else this.audio_obj.play();

      if (this.analyser == null) {
        this.initAnalyser();
      }
      requestAnimationFrame(this.timeUpdated);
    },
    pause() {
      this.audio_obj.pause();
    },
    toggleSpeed() {
      const speedArray = [
        "0.5",
        "0.7",
        "1",
        "1.1",
        "1.2",
        "1.3",
        "1.4",
        "1.5",
        "1.6",
        "1.7",
        "1.8",
        "1.9",
        "2",
      ];
      const currentIndex = speedArray.indexOf(this.speed.toString());
      let newIndex = currentIndex + 1;

      if (newIndex > speedArray.length - 1) newIndex = 0;

      this.speed = parseFloat(speedArray[newIndex]);
      this.audio_obj.playbackRate = this.speed;
    },
    barClick(e) {
      const clickPos = e.clientX - this.bar.x;
      this.audio_obj.currentTime = (clickPos * this.duration) / this.bar.width;
      this.handle_click = true;
    },
    mouseDown(e) {
      if (e.target.id == "handle") this.handle_click = true;
    },
    resizeHandler() {
      this.bar = this.$refs.bar.getBoundingClientRect();
    },
    formatSeconds(secs) {
      const hr = Math.floor(secs / 3600);
      const min = Math.floor((secs - hr * 3600) / 60);
      const sec = Math.floor(secs - hr * 3600 - min * 60);
      return [hr, min, sec].map((a) => a.toString().padStart(2, '0')).join(":");
    },
    moveHandler(e) {
      let barWidth = this.bar.width;
      let barLeft = this.bar.x;
      let barRight = this.bar.right;

      if (this.handle_click) {
        let left = e.clientX - barLeft;
        if (left < 0) left = 0;

        if (left > barWidth) left = barWidth;

        this.current_time = (left * this.duration) / barWidth;
        this.audio_obj.currentTime = this.current_time;
      }
    },
    moveAudio(direction, amount) {
      let newTime = this.current_time;
      amount = parseInt(amount);

      switch (direction) {
        case "increase":
          newTime = newTime + amount;
          break;
        case "decrease":
          newTime = newTime - amount;
          break;
      }

      if (newTime > this.duration || newTime < 0) newTime = this.current_time;

      this.audio_obj.currentTime = newTime;
    },
    updateAudio: function (index) {
    },
    changeTrack(index) {
      this.audio_index = index;
      this.show_list = false;
    },
    changeVolume(volume) {
      this.audio_obj.volume = volume;
      this.$store.commit("VOLUME_change", volume);
    },
    getVolume() {
      return this.$store.state.player.volume || .8;
    }
  },
  computed: {
    endTime() {
      return this.formatSeconds(this.duration);
    },
    currentTime() {
      return this.formatSeconds(this.current_time);
    },
    handlePosition() {
      return (this.current_time * 100) / this.duration + "%";
    },
    leftTime() {
      return this.duration - this.current_time;
    },
    maxAudioPin() {
      return this.audio_pins
          .slice()
          .sort((a, b) => b - a)
          .slice(0, 1);
    },
    normlizeAudioPins() {
      // 20 is the max length
      return this.audio_pins.map((p) => {
        return (p * 20) / this.maxAudioPin;
      });
    },
    info() {
      return this.book && `<p>${this.book.info.join("</p><p>")}</p>`;
    },
    coverArtTransition() {
      let scale = (this.cover_art_average * 100) / 20 / 100;
      if (scale < 0.98) scale = 0.98;

      return {
        transform: `scale(${scale})`,
      };
    },
    currentArt() {
      return ((this.book.chapters[this.audio_index] &&
              this.book.chapters[this.audio_index].art) ||
          'https://www.zastavki.com/pictures/640x480/2020Girls___Beautyful_Girls_Beautiful_brown-eyed_girl_with_a_bouquet_of_white_wildflowers_149403_29.jpg');
    },
  },
  watch: {
    audio_index(newIndex, oldVal) {
      this.audio_obj.src = this.book.chapters[newIndex].link;
      this.is_playing = false;
      this.playAudio();
      this.is_loading = true;
      this.playerData.switchRoute(newIndex);
    },
  },
};
</script>
