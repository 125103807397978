<template>
  <svg id='spectrum'>
    <polygon x="0" y="0" :points="points"></polygon>
  </svg>
</template>

<script>

export default {
  name: 'Spectrum',
  props: ["pins"],
  data: function () {
    return {
      radius: 50,
      adjust: 0,
      average: 0,
      cover_art: null
    };
  },
  mounted: function () {
    const width = this.$el.getBoundingClientRect().width;
    const innerWidth = (width * 100) / 130;
    this.adjust = (width - innerWidth) / 2;
    this.radius = innerWidth / 2;
  },
  methods: {
    valueToPoint: function (value, index) {
      let angle = (360 * index) / this.total - 90;

      // convert to radians
      angle = (angle * Math.PI) / 180;

      var cos =
          Math.cos(angle) * (this.radius + value) + this.radius + this.adjust;
      var sin =
          Math.sin(angle) * (this.radius + value) + this.radius + this.adjust;
      return `${cos},${sin}`;
    }
  },
  computed: {
    points: function () {
      let average_count = 0;
      const value = this.pins
          .map((p, index) => {
            average_count += p;
            return this.valueToPoint(p, index);
          })
          .join(" ");
      this.$emit("average", average_count / this.total);

      return value;
    },
    total: function () {
      return this.pins.length;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
