export default class PlayItemData {
    readonly id: number;
    readonly title: string;
    readonly link: string;
    readonly downloadLink: string;
    readonly art: string;

    constructor(id: number, title: string, link: string, art: string) {
        this.id = id;
        this.title = title;
        this.link = link;
        this.art = art;
    }

    getId(): number {
        return this.id;
    }

    getTitle(): string {
        return this.title;
    }

    getLink(): string {
        return this.link;
    }

    getDownloadLink(): string {
        return this.link;
    }

    getArt(): string {
        return this.art;
    }
}