<template>
  <div class="playlist-container">
    <div class="list-container">
      <div
          class="list-item"
          v-for="(c, i) in playerData.getPlaylist()"
          v-bind:key="i"
          :class="{ active: playerData.getCurrentPlaylistItemIndex() === i }"
      >
        <div class="loading" v-if="playerData.isLoading()"></div>
        <div class="title flex flex-middle w100">
          <span class="flex-1" @click="changeTrack(i)">{{ c.getTitle() }}</span>
          <span class="flex-0">
            <a :href="c.getDownloadLink()"
               :download="c.getTitle() + '.mp3'"
               title="Download"
               class="link"
               onclick="event.stopPropagation()"><i class="fas fa-download"></i></a>
          </span>
        </div>
        <div class="meta"></div>
      </div>
    </div>
  </div>
</template>

<script>
import PlayerData from "@/dtos/PlayerDto.ts";

export default {
  name: "PlaylistContainer",
  props: {"playerData": PlayerData,},
  methods: {
    changeTrack(index) {
      this.$emit('changeTrack', index);
    },
  }
}
</script>

<style scoped>

</style>