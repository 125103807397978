import PlayItemData from './PlayItemData';
import {Router} from "vue-router/dist/vue-router";
import slugify from "slugify";

export default class PlayerData {
    private loading: boolean = true;
    private playlist: PlayItemData[] = [];
    private currentPlaylistItemIndex: number = -1;
    private $router: Router;

    constructor($router: Router) {
        this.$router = $router;
    }

    setIsLoading(isLoading: boolean): this {
        this.loading = isLoading;
        return this;
    }

    isLoading(): boolean {
        return this.loading;
    }

    setPlaylist(playlist: PlayItemData[]): this {
        this.playlist = playlist;
        return this;
    }

    getPlaylist(): PlayItemData[] {
        return this.playlist;
    }

    setCurrentPlaylistItemIndex(index: number): this {
        this.currentPlaylistItemIndex = index;
        return this;
    }

    getCurrentPlaylistItemIndex(): number {
        return this.currentPlaylistItemIndex;
    }

    initiateRoutes(): Number {
        this.playlist.forEach((item) => {
            this.$router.addRoute({
                name: item.getTitle(),
                path: '/play-' + slugify(item.getId() + '-' + item.getTitle()),
                meta: {
                    id: item.getId(),
                    title: item.getTitle(),
                    artWork: item.art
                }
            });
        });

        if (
            this.$router.currentRoute
            && this.$router.currentRoute.path
        ) {
            const audioIndex = this.$router.getRoutes().findIndex((r) => {
                return r.path === this.$router.currentRoute.path
            });
            if (audioIndex !== -1) {
                return audioIndex;
            }
        }
        !this.$router.name && this.$router.getRoutes().length && this.switchRoute(0);
        return this.$router.getRoutes().length ? 0 : -1;
    }

    switchRoute(index: number) {
        let route = null;
        if (this.$router.currentRoute
            && this.$router.currentRoute.path === this.$router.getRoutes()[index].path) {
            route = this.$router.currentRoute;
        }
        if (!route && this.$router.getRoutes()[index]) {
            route = this.$router.getRoutes()[index];
            this.$router.replace(route);
        }
        if (this.$router.getRoutes()[index]) {
            route = this.$router.getRoutes()[index];
            this.updatePageTitle(index + 1, this.$router.getRoutes().length, route.meta.title);
            this.updateTabIcon(route.meta.artWork);
            this.updateMediaSession(route.meta.title, route.meta.artWork);
        }
    }

    updatePageTitle(index: number, atAll: number, title: string) {
        window.top.document.title = `${title} - ${index}/${atAll}: PJ Suggested Music`;
    }

    updateTabIcon(artWork: string) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.setAttribute('rel', 'icon');
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.setAttribute('href', artWork);
    }

    updateMediaSession(title: string, artWork: string) {
        if ('mediaSession' in navigator) {
            // @ts-ignore
            navigator.mediaSession.metadata = new MediaMetadata({
                title: title,
                artist: 'DJ Peedjack',
                album: 'suggested music',
                artwork: [
                    { src: artWork },
                ]
            });
        }
    }
}