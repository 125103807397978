<style lang="scss">
.ar-line-control {
  position: relative;
  height: 0.1em;
  background-color: var(--audio-bar-bg);
  border-radius: 0.3em;

  &__head {
    position: absolute;
    height: inherit;
    background-color: #616161;
    border-radius: inherit;
  }
}
</style>

<template>
  <div
      :ref="refId"
      class="ar-line-control">
    <div class="ar-line-control__head" :style="calculateSize"></div>
    <div
        class="handle"
        :style="calculatePos"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    refId: {type: String},
    eventName: {type: String},
    percentage: {type: Number, default: 0},
    rowDirection: {type: Boolean, default: true}
  },
  data() {
    return {
      position: this.percentage,
      handle_click: false,
      bar: null,
    };
  },
  methods: {
    mouseDown(e) {
      if (e.target.className === "handle")
        this.handle_click = true;
    },
    moveHandler(ev) {
      if (this.handle_click) {
        let barWidth = this.bar.width;
        let barLeft = this.bar.x;
        let left = ev.clientX - barLeft;
        if (left < 0) left = 0;
        if (left >= barWidth) {
          this.position = .999999999;
        } else {
          this.position = left / barWidth;
        }
        setTimeout(() => {
          this.$emit('change-linehead', this.position);
        }, 100);
      }
    },
  },
  mounted() {
    this.bar = this.$refs[this.refId].getBoundingClientRect();
    document.addEventListener("mousedown", this.mouseDown);
    document.addEventListener("mouseup", (e) => (this.handle_click = false));
    document.addEventListener("mousemove", this.moveHandler);
  },
  computed: {
    calculatePos() {
      const value = this.position < 1 ? this.position * 100 : this.position;
      return `${this.rowDirection ? 'left' : 'top'}: ${value}%`
    },
    calculateSize() {
      const value = this.position < 1 ? this.position * 100 : this.position;
      return `${this.rowDirection ? 'width' : 'height'}: ${value}%`
    },
  }
}
</script>
