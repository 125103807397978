<style lang="scss">
.ar-volume {
  display: flex;
  align-items: center;
  line-height: 10px;
  opacity: .7;

  &-bar {
    width: 15rem;
    height: 0.1em;
    background-color: var(--audio-bar-bg);
    border-radius: 0.3em;
    position: relative;
  }

  &__icon {
    fill: #747474;
    width: 24px;
    height: 24px;
    border: 0;
    border-radius: 0;
    padding: 0;
    background-color: unset;
    margin-right: 3px;
  }

  .ar-volume-bar .ar-line-control__head {
    flex: 1 1 auto;
  }

  &:hover .handle {
    transform: translate(-50%, -50%) scale(1.5);
  }

  .handle {
    height: 0.6em;
    width: 0.6em;
    border-radius: 50%;
    cursor: pointer;
    background-color: var(--audio-handle-bg);
    position: absolute;
    transform: translate(-50%, -50%) scale(0);
    top: 50%;
    // box-shadow: 0 0 0rem 0.2rem rgba(255, 255, 255, 0.2);
    z-index: 4;
    transition: transform 0.3s;
  }
}
</style>

<template>
  <div class="ar-volume" v-show="supported">
    <icon-button class="ar-volume__icon" name="volume"/>
    <line-control
        class="ar-volume-bar"
        ref-id="volume"
        :percentage="volume"
        @change-linehead="onChangeLineHead"/>
  </div>
</template>

<script>
import IconButton from './IconButton'
import LineControl from './LineControl'

export default {
  props: {
    initialVolume: Number,
  },
  data() {
    return {
      volume: this.initialVolume,
      supported: true,
    }
  },
  components: {
    IconButton,
    LineControl
  },
  methods: {
    onChangeLineHead(val) {
      if (val >= 0) {
        this.$emit('volumeChanged', val);
      }
    },
    detectMouseMove() {
      this.supported = false;
    }
  },
  mounted() {
    if (!matchMedia('(pointer:fine)').matches) {
      this.detectMouseMove();
    }
  },
  watch: {
    initialVolume(newVal) {
      this.volume = newVal;
    }
  }
}
</script>
